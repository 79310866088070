<template>
    <v-menu v-if="SELECTED_COMPANY"
        v-model="menuSelect" 
        :close-on-content-click="false"  
        offset-y  offset-overflow
        rounded="xl" max-width="350" nudge-width="200"
        > 
        <template v-slot:activator="{ on }"> 
            <v-card v-on="on" class="mb-1 ml-2 mr-2 pa-0" outlined  >  
                <v-list-item class="ma-0 pa-0"> 
                    <v-list-item-avatar tile> 
                    <v-badge :value="SHOW_OFFLINE && AttachedDevice?.server"
                        bordered
                        :class="MBS.data.animate.zoom.in"
                        :color="onlineIndicator" dot
                        overlap b>  
                        <v-icon class=""  size="35">{{
                            AttachedDevice?
                                AttachedDevice.icon_image?AttachedDevice.icon_image.icon?
                                    AttachedDevice.icon_image.icon
                                    :"mdi-devices"
                                :"mdi-devices"
                            :"mdi-devices"
                        }}</v-icon>
                    </v-badge> 
                    </v-list-item-avatar>
                    <v-list-item-content class="mr-0" >
                        <v-list-item-title>
                            <div v-if="!nav.min"  class="not-f5 font-weight-bold grey--text text--darken-1">{{
                            AttachedDevice?AttachedDevice.name:"NOT NAMED"
                        }}</div> 
                        </v-list-item-title>
                        <v-list-item-subtitle>{{AttachedDevice?AttachedDevice.computer_usage_option_name:"NOT NAMED"}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar class="ml-0"><v-icon>mdi-menu-down</v-icon></v-list-item-avatar>
                </v-list-item>  
            </v-card>  
        </template>  
        <v-card   
            color="lighten-1" class="">  

            <!-- //HEADER -->
            <v-card-title class="secondary white--text" dark >
                <v-list-item  dark> 
                    <v-list-item-content>
                        <v-list-item-title>Name Company Device</v-list-item-title>   
                        <v-list-item-subtitle>Select to attach company named device.</v-list-item-subtitle>   
                    </v-list-item-content>
                    <v-list-item-action><v-btn icon="" @click="menuSelect=false"><v-icon>close</v-icon></v-btn></v-list-item-action>
                </v-list-item>
            </v-card-title> 
             
            <!-- //ATTACHED DEVICE --> 
            <v-list class="ma-0 pa-2"  align-center>
                <!-- <div class="font-weight-bold grey--text text--darken-2">ATTACHED:</div> -->
                <v-list-item-subtitle class="mx-4 grey--text">Attached Named Device</v-list-item-subtitle>
                <template v-if="AttachedDevice">
                    <v-list-item >  
                        <v-list-item-avatar tile>  
                            <v-badge :value="SHOW_OFFLINE && AttachedDevice?.server"
                                bordered
                                :class="MBS.data.animate.zoom.in"
                                :color="onlineIndicator" dot
                                overlap >   
                                <v-icon  size="40">{{AttachedDevice.icon_image?AttachedDevice.icon_image.icon:'mdi-devices'}}</v-icon>
                            </v-badge> 
                        </v-list-item-avatar>
                        <v-list-item-content> 
                            <v-list-item-title>{{AttachedDevice.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{AttachedDevice.computer_usage_option_name}}</v-list-item-subtitle>
                            <!-- <v-list-item-subtitle v-if="AttachedDevice?.server"><small class="font-weight-bold  --text">{{AttachedDevice?.server?.network_ip_address}} ({{AttachedDevice?.server?.name}})</small></v-list-item-subtitle> -->
                        </v-list-item-content> 
                        <v-list-item-action>  
                            <v-btn icon="" @click="CONFIRM_DELETING_ATTACHED_DEVICE()"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-layout row wrap>
                        <v-spacer></v-spacer>
                        <v-btn class="mr-3" small color="secondary"  @click="INITIATE_LOCAL_SERVER(AttachedDevice,true)"><v-icon>mdi-refresh</v-icon> Refresh connection</v-btn>
                    </v-layout>
                </template>
                <v-list-item v-else>  
                    <v-list-item-avatar tile> 
                        <v-badge :value="true"
                            bordered
                            :class="MBS.data.animate.zoom.in"
                            :color="'red'" dot
                            overlap > 
                            <v-icon size="40">mdi-devices</v-icon> 
                        </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content> 
                        <v-list-item-title>Not Named</v-list-item-title>
                        <v-list-item-subtitle>Select & attach named device</v-list-item-subtitle>
                    </v-list-item-content> 
                </v-list-item>
                 
            </v-list> 
            <v-divider></v-divider>  
            <!-- // DEVICES --> 
            <v-card flat v-if="!MBS.actions.SIZE(Computers)" height="100">
                <v-layout fill-height align-center justify-center>
                    <v-chip large  color="b1" >
                        <v-icon class="mx-5" color="grey">warning</v-icon>
                        <span class="grey--text  mr-5">
                            No Registered Computer
                        </span>
                    </v-chip> 
                </v-layout> 
            </v-card>
            <v-list v-else ref="body_height" dense :max-height="vsb.scroll?vsh-275:''"  
                :class="vsb.scroll?'mbs-scroll-y':''">   
                <v-list-item-subtitle class="mx-4 grey--text">Select & attach named device</v-list-item-subtitle>
                <v-list-item @click="CONFIRM_ATTACHING_DEVICE(computer)" class="" v-for="(computer,index) in Computers">  
                    <v-list-item-action> 
                        <v-icon>{{computer.icon_image?computer.icon_image.icon:"mdi-devices"}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content> 
                        <v-list-item-title>{{computer.name}}</v-list-item-title>
                        <v-list-item-subtitle>{{computer.computer_usage_option_name}} </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="computer?.server" class="  --text font-weight-bold not-f5">{{computer.server?.network_ip_address}} <span>( {{computer.server_name}})</span></v-list-item-subtitle>
                    </v-list-item-content> 
                </v-list-item>   
            </v-list>  
            <!-- //FOOTER  -->
            <v-divider></v-divider> 
            <v-card-actions class="pa-0">
                <v-btn @click="menuSelect=false"
                    :to="MBS.actions.COMPANY_LINK('/settings/devices/computers',true)" 
                    text width="100%" height="50"  
                    color="secondary">Manage Computers</v-btn>
            </v-card-actions>
        </v-card>  
    </v-menu>   
</template> 
<script>
let PAGE_NAME = 'LIVE-TILL'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import {mapState } from "vuex" 
export default { 
    props:['nav','min', ],
    data(){
        return{   
            dialog:false, 
            menuSelect:false, 
            input:{}, 
            body_height:0, 
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            
            
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){ 
        this.input={}
        this.inputLoading=false   
        // this.INITIATE_LOCAL_SERVER(this.CurrentTill)     
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        }, 
        vsb(){ 
            let body_height = this.body_height 
            let height = this.vs.height 
            body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
            let diff = height-body_height 
            return {
                height:height,
                body_height:body_height,
                diff:diff,
                scroll:body_height?diff>290?false:true:false,
            }
        },

         
        onlineIndicator(){
            const online = this.online
            const online_lan1 = this.online_lan1
            let light = "green"
            if (!online_lan1) {
                light = "red"
            } else {
                
            }
            return light
        },

       
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SHOW_OFFLINE: state=> state.items['SHOW_OFFLINE'],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            online: state=> state.users['ONLINE_STATUS'],
            online_lan1: state=> state.lan['ONLINE_LAN1'],
            online_lan2: state=> state.lan['ONLINE_LAN2'],
            link_lan1: state=> state.lan['LINK_LAN1'],
            link_lan2: state=> state.lan['LINK_LAN2'],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
            JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
            Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
            JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
            LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],

            LocalDevices: state=> state.locals[(DATA.ITEMS.LOCAL_DEVICES.values).toUpperCase()],
        }), 
        DeviceData(){
            return this.JoinedDevices?this.JoinedDevices:this.Devices
        },
        AttachedDevice(){
            let devices = this.LocalDevices 
            if(!devices){return null}
            let filtered = devices.find(pc=>pc.key == 'attached_device')
            return filtered
        },
        Computers(){
            let devices = this.DeviceData  
            if(!devices){return null}
            let Computers = devices.filter(item=>{
                return this.MBS.actions.TEXT_UP(item.device_type)
                    == this.MBS.actions.TEXT_UP(DATA.ITEMS.COMPUTERS.value)
            }) 
            return Computers
        },

        TillsData(){
            return this.JoinedTills?this.JoinedTills:this.Tills
        },
        LocalCashup(){    
            let cashups = this.LocalCashups  
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null 
            this.day_shift_key = local_cashup?.day_shift_key 
            return local_cashup
        }, 
        CurrentTill(){
            let tills = this.TillsData
            let cashup = this.LocalCashup 
            if(!tills || !cashup){return null}
            let filled = tills.find(item=>{
                return item.key == cashup.till_key
            })  
            return filled
        }, 
       
        liveTime(){
            let time = this.MBS.date.live 
            return time
        }, 
        online(){   
            return this.$store.getters.getOnlineStatus
        },
         
    },
    methods:{ 
        async INITIATE_LOCAL_SERVER(device,force){
            try {
                const online = this.online_lan1
                const link = this.link_lan1
                let server = device?.server
                const server_code = device?.server_code 
                if (server_code && !server) {
                    server = this.$store.getters.getDeviceByCode(server_code)    
                }
                const network_ip_address_link = server?.network_ip_address_link 
                const network_link = network_ip_address_link+"/api/"
                if (!force && online && link == network_link) {
                    console.log(link,'same link.........................');
                    return
                }
                console.log(network_link,link,online,"++++************************************++");
                
                this.$store.dispatch("lan_start",{device:device,lan:1})
                .then(res=>{
                    console.log(res,"LLLLLLLLLLLLL-----res------"); 
                }).catch(error=>{
                    console.log(error,"LLLLLLLLLLLLL-----err------"); 
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_LOCAL_SERVER',
                    page:PAGE_NAME, 
                })
            }  
        },
         
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CONFIRM_ATTACHING_DEVICE(device){ 
            try { 
                console.log(device,'at........');
                let icon = device.icon_image? device.icon_image.icon? device.icon_image.icon:"mdi-devices":"mdi-devices"
                 
                let mbs_text = this.MBS.text.item_action("Device","Attach")
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:mbs_text.title,
                    text:mbs_text.text+". Enter your password to confirm this action.",
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=ATTACH-DEVICE",
                        device:device,
                        phone_number_start:true,
                        all_users:true,
                        get_access:{
                            page_name:"Settings"
                        }
                    }
                })
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_ATTACHING_DEVICE',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CONFIRM_DELETING_ATTACHED_DEVICE(device){ 
            try { 
                let mbs_text = this.MBS.text.item_action("Device","Remove Attached")
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    fixed:true,
                    icon:"mdi-delete",
                    title:mbs_text.title,
                    text:mbs_text.text+". Enter your password to confirm this action.",
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=DELETE-ATTACH-DEVICE", 
                    }
                })
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_ATTACHING_DEVICE',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ATTACH_DEVICE(device){ 
            try { 
                let company = this.SELECTED_COMPANY
                let company_key = company?company.company_key:null
                if (!company_key) {
                    return
                }
                DB.addItems({
                    name:DATA.ITEMS.LOCAL_DEVICES.values,
                    data:{
                        ...device,
                        key2:device.key,
                        key:'attached_device',
                        company:company,
                        company_key:company_key
                    },
                    update_locals:true 
                }).then(res=>{
                    console.log(res,'res...');
                }).catch(error=>{
                    console.log(error,'err.....');
                })
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ATTACH_DEVICE',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        UPDATE_ATTACHED_DEVICE(device){ 
            try { 
                const attached_device = this.AttachedDevice
                const attached_key = attached_device?.key
                const device_key = attached_device?.key2
                if (attached_key!="attached_device" ||!device_key || !device) {
                    return
                }
                const updateDetails = {
                    ...attached_device,
                    ...device, 
                    key:attached_key
                } 
                DB.addItems({
                    name:DATA.ITEMS.LOCAL_DEVICES.values,
                    data:updateDetails,
                    update_locals:true 
                }).then(res=>{
                    console.log(res,'UPDATE_ATTACHED_DEVICE res...');
                }).catch(error=>{
                    console.log(error,'UPDATE_ATTACHED_DEVICE err.....');
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'UPDATE_ATTACHED_DEVICE',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        DELETE_ATTACH_DEVICE(){ 
            try { 
                DB.deleteItems({
                    name:DATA.ITEMS.LOCAL_DEVICES.values,
                    key:'attached_device',
                    update_locals:true 
                }).then(res=>{
                    console.log(res,'res...');
                }).catch(error=>{
                    console.log(error,'err.....');
                })
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DELETE_ATTACH_DEVICE',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=ATTACH-DEVICE') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.ATTACH_DEVICE(action.device)
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } if (action.code ===PAGE_NAME+'=DELETE-ATTACH-DEVICE') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.DELETE_ATTACH_DEVICE(action.device)
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    }    
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);        
    },
    watch:{  
        input(){
             
        }, 
        vsh(){
            if (this.menuSelect) {
                this.menuSelect = false
                setTimeout(() => {
                    this.menuSelect = true 
                }, 500);
            }  
        },
        menuSelect(value){ 
            setTimeout(() => {
                this.body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:0;   
            }, 100);
        },
        AttachedDevice(value){ 
            this.INITIATE_LOCAL_SERVER(value)   
        },
        CurrentTill(value){ 
        },
        DeviceData(value){
            const attached_device = this.AttachedDevice
            const device_key = attached_device?.key2
            if (value) {
                const device = value.find(item=>item.key==device_key)
                this.UPDATE_ATTACHED_DEVICE(device) 
                
            } 
        }

        
    }
}
</script> 